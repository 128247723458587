import { z } from 'zod'

import {
  PasswordSchema,
  RegistrationFormType,
  similarityExistsWithPassword,
} from '../../../../routes/Register/AccountCreation/RegistrationFormStep/RegistrationForm.schema'

// #region Validation
export const ChangePasswordValidation = z
  .object({
    newPassword: PasswordSchema,
    confirm: PasswordSchema,
    email: z.string(),
    preferredFirstname: z.string(),
    preferedSurname: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.newPassword && data.confirm.length > 0 && data.newPassword !== data.confirm) {
      console.log(data.newPassword, data.confirm, 'NO MATCH')
      ctx.addIssue({
        path: ['confirm'],
        message: 'account-creation.form-passwordsmustmatch',
        code: z.ZodIssueCode.custom,
      })
    }

    const mutatedData = {
      password: data.newPassword,
      confirm: data.confirm,
      email: data.email,
      preferredFirstname: data.preferredFirstname,
      preferredSurname: data.preferedSurname,
    } as RegistrationFormType
    const similarityExists = similarityExistsWithPassword(mutatedData)

    if (similarityExists) {
      ctx.addIssue({
        path: ['newPassword'],
        message: 'microcopies.form-password-consecutive',
        code: z.ZodIssueCode.custom,
      })
    }
  })
// #endregion

//#region Types
export type ChangePasswordFormValues = z.infer<typeof ChangePasswordValidation>
//#endregion
