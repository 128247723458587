import { z } from 'zod'

import { isValidName } from '../../../../utils/validation-utils'

// Checks if string containts upper and lowercase characters
const UPPER_AND_LOWERCASE_CHECK = /.*[a-z].*[A-Z]|[A-Z].*[a-z].*/
// Checks if string contains a digit
const DIGIT_CHECK = /.*\d.*/
// Checks if the string contains special characters
const SPECIAL_CHARACTERS_CHECK = /.*[\W_]+.*/
// Accepted characters for phone number
const ACCEPTED_PHONE_NUMBER_CHARACTERS = '0123456789+'
// Max length of phoneNumber
const MAX_LENGTH_PHONE_NUMBER = 20
// Min length of password
const MIN_LENGTH_PASSWORD = 16

// #region Validation

// #region Custom validation functions

export function similarityExistsWithPassword(data: RegistrationFormType): boolean {
  const SIMILARITY_INDEX = 3
  const { email, preferredFirstname, preferredSurname, password } = data
  const normalizedPassword = password.toLowerCase()

  // If password is empty, return false
  if (!normalizedPassword) {
    return false
  }

  // If password is less than 16 characters, return false
  if (normalizedPassword.length < MIN_LENGTH_PASSWORD) {
    return false
  }

  // Iterates through the password and checks if the substring exists in the email, firstname or surname
  for (let i = 0; i < normalizedPassword.length; i++) {
    const substring = normalizedPassword.slice(i, i + SIMILARITY_INDEX)

    if (substring.length < SIMILARITY_INDEX) {
      continue
    }

    const normalizedEmail = email.toLowerCase()
    const normalizedFirstName = preferredFirstname.toLowerCase()
    const normalizedSurname = preferredSurname.toLowerCase()

    if (normalizedEmail.includes(substring)) {
      return true
    }

    if (normalizedFirstName.includes(substring)) {
      return true
    }

    if (normalizedSurname.includes(substring)) {
      return true
    }
  }

  return false
}

// #endregion

/**
 * Password should consists of at least 16 characters, one uppercase letter, one lowercase letter, one digit and one special character.
 * If password contains three consecutive characters, it is invalid.
 */
export const PasswordSchema = z
  .string()
  .refine(value => value.length >= MIN_LENGTH_PASSWORD, {
    message: 'account-creation.form-password-length',
  })
  .refine(value => UPPER_AND_LOWERCASE_CHECK.test(value), {
    message: 'account-creation.form-password-letters',
  })
  .refine(value => DIGIT_CHECK.test(value), {
    message: 'account-creation.form-password-digits',
  })
  .refine(value => SPECIAL_CHARACTERS_CHECK.test(value), {
    message: 'account-creation.form-password-characters',
  })

export const ValidatePhone = z.string().refine(phone => {
  // First character must be a plus sign
  if (phone[0] !== '+') {
    return false
  }

  // Check if there are more than one plus sign
  const numberOfPlusChars = phone.split('+').length - 1

  if (numberOfPlusChars > 1) {
    return false
  }

  // Check if there are any invalid characters
  for (const char of phone) {
    if (!ACCEPTED_PHONE_NUMBER_CHARACTERS.includes(char)) {
      return false
    }
  }

  // Checks the phonenumber length
  return phone.length < MAX_LENGTH_PHONE_NUMBER
})
// Validates e-mail
export const EmailValidation = z.string().email()
// Validates verification key
export const verificationKeyValidation = z.string().min(7)

export const RegistrationFormSchema = z
  .object({
    firstname: z.string(),
    preferredFirstname: z
      .string()
      .min(1)
      .refine(firstname => {
        return isValidName(firstname)
      }),
    surname: z.string(),
    preferredSurname: z
      .string()
      .min(1)
      .refine(surname => {
        return isValidName(surname)
      }),
    ssn: z.string().optional(),
    email: EmailValidation,
    verificationKey: verificationKeyValidation,
    tel: ValidatePhone,
    password: PasswordSchema,
    confirm: PasswordSchema,
    terms: z.boolean().refine(value => value),
  })
  .superRefine((data, ctx) => {
    if (data.confirm && data.confirm.length > 0 && data.password !== data.confirm) {
      console.log(data.password, data.confirm, 'NO MATCH')
      ctx.addIssue({
        path: ['confirm'],
        message: 'account-creation.form-passwordsmustmatch',
        code: z.ZodIssueCode.custom,
      })
    }

    const similarityExists = similarityExistsWithPassword(data)
    console.log(similarityExists, 'SIMILARITY EXISTS')

    if (similarityExists) {
      ctx.addIssue({
        path: ['password'],
        message: 'microcopies.form-password-consecutive',
        code: z.ZodIssueCode.custom,
      })
    }
  })

// #endregion

// #region Type of the form values
export type RegistrationFormType = z.infer<typeof RegistrationFormSchema>
// #endregion
