import { compose } from '@reduxjs/toolkit'
import { useQuery } from '@tanstack/react-query'
import { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import styled from 'styled-components'

import me from '../../api/me'
import Container from '../../components/Container'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import { DEFAULT_SERVICE_ID, QUERY_KEY_USER_PROFILE, SESSION_KEY_SERVICE } from '../../constants'
import HeaderContainer from '../../containers/HeaderContainer/HeaderContainer'
import { PortalWrapper } from '../../containers/PortalRouteWrapper/PortalRouteWrapper'
import RouteWrapper from '../../containers/RouteWrapper/RouteWrapper'
import withAuth from '../../hocs/withAuth'
import withL3Access from '../../hocs/withL3Access'
import useL3Access from '../../hooks/useL3Access'
import useService from '../../hooks/useService'
import { setUserInfo } from '../../redux/slices/meSlice'
import { RootState } from '../../redux/store'
import { Spacing } from '../../shared/enums'
import { IUserInformation } from '../../shared/interfaces'
import ChangeBasicInformationForm from '../Portal/UserProfile/BasicInformation/ChangeBasicInformationForm'
import ChangeEmailForm from '../Portal/UserProfile/Email/ChangeEmailForm'
import ChangePasswordForm from '../Portal/UserProfile/Password/ChangePasswordForm'

const Main = styled.main`
  padding: ${Spacing.ExtraLarge} ${Spacing.ExtraLarge};
`

const Profile: FunctionComponent = () => {
  const { t: translate } = useTranslation()
  const hasL3Access = useL3Access()
  const { isLoading, service } = useService()

  const dispatch = useDispatch()

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const hasRegistered = useSelector((state: RootState) => state.me.uid)

  const userInfoQuery = useQuery([QUERY_KEY_USER_PROFILE], me.getMyUserInformation)

  useEffect(() => {
    if (userInfoQuery.status === 'success' && userInfoQuery.data) {
      dispatch(setUserInfo(userInfoQuery.data))
    }
  }, [dispatch, userInfoQuery.data, userInfoQuery.status])

  useEffect(() => {
    if (!isLoading && service && service.id !== DEFAULT_SERVICE_ID) {
      sessionStorage.setItem(SESSION_KEY_SERVICE, JSON.stringify(service))
    }
  }, [isLoading, service])

  if (!hasRegistered) return <Navigate to="/register/create-account" />

  return (
    <RouteWrapper title={translate('user-profile.title')}>
      <HeaderContainer />
      <PortalWrapper>
        <Main>
          <Heading level="h1" visualLevel="h4" color="bf-blue">
            {translate('user-profile.title')}
          </Heading>

          {!hasL3Access ? <Text>{translate('user-profile.modify-l3access')}</Text> : null}

          <Container gap="extra-large" padding="none" color="bf-blue">
            <ChangeBasicInformationForm data={userInfoQuery.data} />
            <ChangeEmailForm data={userInfoQuery.data} />
            <ChangePasswordForm data={userInfoQuery.data as IUserInformation} />
          </Container>
        </Main>
      </PortalWrapper>
    </RouteWrapper>
  )
}

export default compose(withAuth, withL3Access)(Profile) as FunctionComponent
