import { BASE_URL } from '../../../constants'
import { ServicePathResponseType } from './UserProfile.schema'

//#region enums
export enum UserProfileRequestKeys {
  updateService = 'updateService',
}
//#endregion

//#region Constants
const SERVICE_USER_HEADERS = { 'Content-Type': 'application/json' }
//#endregion

//#region Requests
export const handleUserServicePatch = async (data: {
  serviceId: string
  selectedOrganization?: string
}): Promise<ServicePathResponseType> => {
  const { serviceId, selectedOrganization } = data
  const serviceUserEndpoint = new URL(`/api/services/${serviceId}/serviceuser`, BASE_URL)

  if (!selectedOrganization) {
    const query = await fetch(serviceUserEndpoint, {
      method: 'PATCH',
      headers: SERVICE_USER_HEADERS,
    })

    return query.json()
  }

  const query = await fetch(serviceUserEndpoint, {
    method: 'PATCH',
    headers: SERVICE_USER_HEADERS,
    body: JSON.stringify({ businessId: selectedOrganization }),
  })

  return query.json()
}
//#endregion
