import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import me from '../../../../api/me'
import Button from '../../../../components/Button/Button'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import ReactFormContainer from '../../../../components/ReactFormWrapper/ReactFormWrapper.component'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import useL3Access from '../../../../hooks/useL3Access'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import PasswordInputs from '../../../../routes/Register/AccountCreation/PasswordInputs/PasswordInputs.component'
import { IUserInformation } from '../../../../shared/interfaces'
import { FormValues } from '../../../../shared/types'
import { ChangePasswordFormValues, ChangePasswordValidation } from './ChangePassword.schema'

type Props = {
  data: IUserInformation
}

const ChangePasswordForm: FunctionComponent<Props> = props => {
  const { data } = props
  const { t } = useTranslation()
  const hasL3Access = useL3Access()
  const dispatch = useDispatch()
  console.log(data)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ChangePasswordFormValues>({
    defaultValues: {
      newPassword: '',
      confirm: '',
      email: data.email,
      preferedSurname: data.surname,
      preferredFirstname: data.firstname,
    },
    resolver: zodResolver(ChangePasswordValidation),
    mode: 'onChange',
  })

  const changePasswordMutation = useMutation({
    mutationFn: (values: FormValues) => me.changePassword(values),
  })

  const handleSuccess = async () => {
    setValue('confirm', '')
    setValue('newPassword', '')
    dispatch(openSnackbar(t('snackbar.password-success'), false))
  }

  useEffect(() => {
    // TODO: Server errors
    if (changePasswordMutation.status === 'success' && changePasswordMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
    } else if (changePasswordMutation.status === 'success' && !changePasswordMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
      handleSuccess()
    } else if (changePasswordMutation.status === 'error') {
      dispatch(setSpinner({ visible: false }))
      dispatch(openSnackbar(t('snackbar.password-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordMutation.data, changePasswordMutation.status])

  const onSubmit = async (values: ChangePasswordFormValues) => {
    const validation = ChangePasswordValidation.safeParse(values)

    if (!validation.success) {
      return console.log('Validation failed:', validation.error)
    }

    dispatch(setSpinner({ visible: true }))
    changePasswordMutation.mutate(values)
  }

  return (
    <ReactFormContainer onSubmit={handleSubmit(onSubmit)}>
      <Container padding="none" margin="none none small none">
        <Heading level="h2" visualLevel="h5" color="bf-blue">
          {t('user-profile.change-password')}
        </Heading>
      </Container>
      <PasswordInputs
        passwordName="newPassword"
        repeatPasswordName="confirm"
        errors={errors}
        register={register}
        t={t}
      />
      {hasL3Access ? (
        <Button
          disabled={Object.keys(errors).length > 0}
          type="submit"
          label={t('user-profile.change-password')}
        />
      ) : null}
    </ReactFormContainer>
  )
}

export default ChangePasswordForm
