import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import organizations from '../../../../api/organizations'
import Button from '../../../../components/Button'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import Modal from '../../../../components/Modal'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import {
  QUERY_KEY_SEND_REQUEST,
  QUERY_KEY_USER_ORGANIZATIONS,
  STORAGE_KEY_LAST_ORGANIZATION,
} from '../../../../constants'
import BusinessSearchContainer from '../../../../containers/BusinessSearchContainer'

const JoinOrganization: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { t: translate } = useTranslation()
  const [isModalOpen, setModalOpen] = useState(false)

  const queryClient = useQueryClient()
  const sendRequestMutation = useMutation({
    mutationKey: [QUERY_KEY_SEND_REQUEST],
    mutationFn: (obj: { businessId: string; role: string; message: string }) =>
      organizations.createRequest(obj),
  })

  useEffect(() => {
    if (sendRequestMutation.status === 'success') {
      setModalOpen(false)
      // TODO: Invalidate my requests query
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_USER_ORGANIZATIONS],
      })
      // Sets the last organization to the one that was just requested
      localStorage.setItem(
        STORAGE_KEY_LAST_ORGANIZATION,
        sendRequestMutation.data?.businessId || ''
      )
      dispatch(openSnackbar(translate('snackbar.organization-request-success'), false))
    } else if (sendRequestMutation.status === 'error') {
      dispatch(openSnackbar(translate('snackbar.organization-request-error'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, sendRequestMutation.status])

  const handleOpen = () => {
    setModalOpen(true)
  }

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (obj: any) => {
      sendRequestMutation.mutate({
        businessId: obj.selectedOrganization.businessId,
        role: obj.role,
        message: obj.message,
      })
    },
    [sendRequestMutation]
  )

  const handleAbort = () => {
    setModalOpen(false)
  }

  const buttonAbort = {
    label: translate('microcopies.cancel'),
    handleClick: handleAbort,
  }

  const buttonSend = {
    label: translate('microcopies.add'),
    handleClick: handleSubmit,
  }

  return (
    <>
      <Container flexDirection="row" padding="none">
        <Button
          label={translate('organization.add-organization-button')}
          onClick={handleOpen}
          variant="secondary"
        />
      </Container>

      <Modal isOpen={isModalOpen}>
        <div>
          <Heading level="h1" visualLevel="h4" color="bf-blue">
            {translate('organization.add-organization')}
          </Heading>
          <BusinessSearchContainer button1={buttonAbort} submitButton={buttonSend} />
        </div>
      </Modal>
    </>
  )
}

export default JoinOrganization
