import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Button from '../../components/Button'
import { IconName } from '../../components/Icon/Icon.interface'
import Input from '../../components/Input'
import Link from '../../components/Link'
import Radio from '../../components/Radio'
import RadioButtonGroup from '../../components/RadioGroup/RadioGroup'
import VisuallyHidden from '../../components/VisuallyHidden'
import { wrapWithStyledError } from '../../hooks/useValidators'
//'src/redux/store'
import { loadOrganization } from '../../redux/slices/organizationSearchSlice'
import { AppDispatch } from '../../redux/store'
import { StyledInputContainer } from '../../routes/Register/AccountCreation/RegistrationFormStep/RegistrationForm.style'
import { IAccountEssentials } from '../../shared/interfaces'
import { validateFinnishBusinessID } from '../../utils/validation-utils'

type Props = {
  button1?: {
    label: string
    icon?: IconName
    iconRight?: boolean
    handleClick: () => void
  }
  submitButton: {
    label: string
    icon?: IconName
    iconRight?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClick: (...args: any) => void
  }
  skipButton?: {
    label: string
    icon?: IconName
    iconRight?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClick: (...args: any) => void
  }
  Registeration?: boolean
  value?: string
}

const BusinessSearchContainer: FunctionComponent<Props> = ({
  button1,
  submitButton,
  skipButton,
  Registeration = false,
  value = '',
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const [inputValue, setInputValue] = useState<string>(value)
  const [selectedOrganization, setSelectedOrganization] = useState<IAccountEssentials | null>(null)
  const [role, setRole] = useState<string | null>(null)
  const [message, setMessage] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (selectedOrganization?.name) {
      setRole('employee')
    } else {
      setRole(null)
    }
  }, [selectedOrganization])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)

    if (event.target.value.length === 0 || validateFinnishBusinessID(event.target.value)) {
      setError(false)
    }

    if (event.target.value.length > 0 && !validateFinnishBusinessID(event.target.value)) {
      setError(true)
    }

    if (validateFinnishBusinessID(event.target.value)) {
      setError(false)
      setIsLoading(true)
      const response = await dispatch(loadOrganization(event.target.value))
      const newSelected = response['payload']
      setSelectedOrganization(newSelected)
      setIsLoading(false)
    } else {
      setSelectedOrganization(null)
    }
  }

  const handleMessageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }

  const renderSelectedOrganization = (): JSX.Element | undefined => {
    if (isLoading) {
      return <span>{t('join-organization.fetching-organization')}...</span>
    }

    if (!error) {
      if (selectedOrganization?.name) {
        return (
          <>
            <span>{t('join-organization.selected-organization')}</span>
            <span style={{ fontWeight: 'bold' }}>{selectedOrganization.name} </span>
          </>
        )
      }

      if (!selectedOrganization) {
        return <span>{t('join-organization.no-selected-organization')} </span>
      }

      if (selectedOrganization && !selectedOrganization.name)
        return (
          <span>
            {t('join-organization.cannot-find-organization')}
            <Link href={t('join-organization.link-to-customer-support')}>
              {t('join-organization.customer-support-request')}
            </Link>
          </span>
        )
    }

    return undefined
  }

  const renderInfo = (): JSX.Element | undefined => {
    if (selectedOrganization && selectedOrganization.name) {
      const infoMessage =
        selectedOrganization.existInUbi && selectedOrganization.existingUsers
          ? t('join-organization.organization-join-request')
          : t('join-organization.organization-direct-join')

      return <p>{infoMessage}</p>
    }

    return undefined
  }

  const renderError = (): JSX.Element | undefined => {
    if (error) {
      return wrapWithStyledError(t('account-creation.form-invalid-businessid'))
    }

    return undefined
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value)
  }

  const handleSubmit = () => {
    submitButton.handleClick({
      selectedOrganization,
      role,
      message,
    })
  }

  const handleSkip = () => {
    skipButton?.handleClick()
  }

  return (
    <div style={{ maxWidth: '36.625rem' }}>
      <StyledInputContainer>
        <Input
          name="BusinessId"
          type="text"
          label={t('account-creation.form-businessid')}
          value={inputValue}
          onChange={handleChange}
          helpText={t('join-organization.organization-search-help-text')}
          helpLink={t('join-organization.link-to-businessid-search')}
          hasError={error}
        />
        {renderError()}
        {renderSelectedOrganization()}
        {!error && renderInfo()}
      </StyledInputContainer>

      {selectedOrganization?.name && selectedOrganization.existingUsers ? (
        <Input
          name="message"
          type="textarea"
          label={t('join-organization.message')}
          value={message}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={handleMessageChange}
        />
      ) : null}

      {/** TODO: Unhide when the functionality is needed: */}
      <VisuallyHidden aria-hidden="true">
        <RadioButtonGroup
          label={`${t('join-organization.select-role-label')} ${
            Registeration ? t('microcopies.optional') : ''
          }`}
          name="role"
        >
          <Radio
            label={t('join-organization.role-consultant')}
            value="consultant"
            checked={role === 'consultant'}
            disabled={!selectedOrganization?.name}
            onChange={handleRadioChange}
          />
          <Radio
            label={t('join-organization.role-employee')}
            value="employee"
            checked={true}
            onChange={handleRadioChange}
            disabled={!selectedOrganization?.name}
          />
        </RadioButtonGroup>
      </VisuallyHidden>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem 0rem',
        }}
      >
        {button1 ? (
          <Button
            label={button1.label}
            onClick={button1.handleClick}
            icon={button1.icon}
            iconRight={button1.iconRight}
            variant="secondary"
          />
        ) : null}
        {Registeration && skipButton ? (
          <Button label={skipButton.label} onClick={handleSkip} variant="secondary" />
        ) : null}
        {submitButton ? (
          <Button
            disabled={!selectedOrganization?.name}
            label={submitButton.label}
            onClick={handleSubmit}
            icon={submitButton.icon}
            iconRight={submitButton.iconRight}
          />
        ) : null}
      </div>
    </div>
  )
}

export default BusinessSearchContainer
