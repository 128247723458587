import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setShowSnackbar } from '../../redux/slices/snackbarSlice'
import { HealthCheckKeys, checkHealth } from './HealthCheck.requests'
import { HealthCheckType } from './HealthCheck.schema'

/**
 * This is standalone logic component that checks the health of the APIM
 * @returns {React.FC}
 */
const HealthCheck: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const query = useQuery({
    queryKey: [HealthCheckKeys.HEALTH_CHECK],
    queryFn: checkHealth,
  })

  useEffect(() => {
    if (!query.data) {
      return
    }

    if (!query.data) {
      return
    }

    let hasErrors = false

    // This usually comes from 404 errors
    if (typeof query.data === 'string') {
      hasErrors = true
    }

    // Tries to get rest of server errors
    if (!hasErrors && query.status === 'error') {
      hasErrors = true
    }

    // Checks the keys from the object if server is healthy
    if (!hasErrors) {
      hasErrors = Object.keys(query.data).some(
        key => query.data[key as keyof HealthCheckType] === false
      )
    }

    if (hasErrors) {
      dispatch(
        setShowSnackbar({
          message: t('snackbar.health-failure'),
          error: true,
          showSnackbar: true,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data])

  return <div />
}

export default HealthCheck
