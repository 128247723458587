import { useMutation } from '@tanstack/react-query'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import organizations from '../../../../api/organizations'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import Text from '../../../../components/Text'
import {
  DEFAULT_SERVICE_ID,
  QUERY_KEY_SEND_REQUEST,
  QUERY_KEY_SKIP_ORGANIZATION_SELECTION,
  STORAGE_KEY_LAST_ORGANIZATION,
} from '../../../../constants'
import BusinessSearchContainer from '../../../../containers/BusinessSearchContainer'
import useInvitation from '../../../../hooks/useInvitation'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import { getService } from '../../WeakIdentification/WeakIdentification.utils'

interface IRegistrationForm {
  handleNext: () => void
}

const JoinOrganizationStep: FunctionComponent<IRegistrationForm> = ({ handleNext }) => {
  const { t: translate } = useTranslation()
  const dispatch = useDispatch()

  // This is for redirect purposes in occasions where service doesn't require organization selection
  const currentService = getService()
  // This useEffect handles the organization selection. If service doesn't require org selection we
  // can skip this step and if it does, we add a form where user can select organization.
  useEffect(() => {
    // This shouldn't happen like ever
    if (!currentService) {
      console.log('No current service')

      return
    }

    if (currentService.id === DEFAULT_SERVICE_ID) {
      return
    }

    // If service doesn't require organization selection, we can skip this step
    const requiresOrganizationSelection =
      currentService.redirection?.preferences?.requireOrgSelection

    if (!requiresOrganizationSelection) {
      return handleNext()
    }
    // If service requires organization selection, we can't skip this step

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentService])

  const invitationId = sessionStorage.getItem('invitationId')

  const { isLoading, invitation } = useInvitation(invitationId)

  const sendRequestMutation = useMutation({
    mutationKey: [QUERY_KEY_SEND_REQUEST],
    mutationFn: (obj: { businessId: string; role: string; message: string }) =>
      organizations.createRequestInRegisteration(obj),
  })

  const skipSelectionMutation = useMutation({
    mutationKey: [QUERY_KEY_SKIP_ORGANIZATION_SELECTION],
    mutationFn: () => organizations.skipOrganizationSelection(),
  })

  const handleSubmit = useCallback(
    (obj: { selectedOrganization: { businessId: string }; role: string; message: string }) => {
      dispatch(setSpinner({ visible: true }))
      sendRequestMutation.mutate({
        businessId: obj.selectedOrganization.businessId,
        role: obj.role,
        message: obj.message,
      })
    },
    [dispatch, sendRequestMutation]
  )

  const handleSkip = useCallback(() => {
    dispatch(setSpinner({ visible: true }))
    skipSelectionMutation.mutate()
  }, [dispatch, skipSelectionMutation])

  useEffect(() => {
    if (sendRequestMutation.status === 'success' || skipSelectionMutation.status === 'success') {
      dispatch(setSpinner({ visible: false }))

      if (sendRequestMutation.data && sendRequestMutation.data.data) {
        localStorage.setItem(
          STORAGE_KEY_LAST_ORGANIZATION,
          sendRequestMutation.data.data.businessId
        )
      }

      handleNext()
    }
  })

  const buttonNext = { label: translate('microcopies.add'), handleClick: handleSubmit }

  const skipButton = {
    label: translate('select-organization.no-organizations-skip'),
    handleClick: handleSkip,
  }

  return (
    <Container padding="none">
      <Heading level="h2" visualLevel="h4" color="bf-blue">
        {translate('join-organization.subtitle')}
      </Heading>
      <Text as="p">{translate('join-organization.ingress')}</Text>
      <Container backgroundColor="bf-light-blue">
        {!isLoading && (
          <BusinessSearchContainer
            submitButton={buttonNext}
            skipButton={skipButton}
            Registeration={true}
            value={invitation ? invitation.organization.businessId : ''}
          />
        )}
      </Container>
    </Container>
  )
}

export default JoinOrganizationStep
