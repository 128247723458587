import { TFunction } from 'i18next'
import { FieldErrors, UseFormRegister } from 'react-hook-form'

type Props = {
  passwordName: string
  repeatPasswordName: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FieldErrors<Partial<any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  t: TFunction
}

const PasswordInputs = (props: Props) => {
  const { errors, register, t, passwordName, repeatPasswordName } = props

  return (
    <>
      <label htmlFor={'password'}>{t('account-creation.form-password')}*</label>
      <input type={'password'} {...register(passwordName)} />
      <span className="help-text">{t('account-creation.form-password-specification')}</span>
      {errors[passwordName] ? (
        <span className="error-message">
          {t('account-creation.form-invalidpassword')} ({t(errors[passwordName]?.message as string)}
          )
        </span>
      ) : null}
      <label htmlFor={'confirm'}>{t('account-creation.form-confirm-password')}*</label>
      <input type={'password'} {...register(repeatPasswordName)} />
      {errors[repeatPasswordName] ? (
        <span className="error-message">{t('account-creation.form-passwordsmustmatch')}</span>
      ) : null}
    </>
  )
}

export default PasswordInputs
