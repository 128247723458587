import { handleAPIGet } from '../../utils/network-utils'
import { HealthCheckType } from './HealthCheck.schema'

export enum HealthCheckKeys {
  HEALTH_CHECK = 'HEALTH_CHECK',
}

/**
 * Checks apim health
 * @returns {Promise<AxiosResponse<HealthCheckType>>}
 */
export const checkHealth = async (): Promise<HealthCheckType> => {
  return handleAPIGet('/health/statuses')
}
